import React from 'react';
import { useQuery } from '@tanstack/react-query'; // Updated import
import { getCommodities } from 'src/store/services';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';
import { toastFn } from 'src/utils/helpers';

const GetCommodityCodes = () => {
  return getCommodities();
};

export const useGetCommodityCodes = () => {
  return useQuery({
    queryKey: ['CommodityCodes'],
    queryFn: () => GetCommodityCodes(),
    staleTime: STALE_TIMES.ONE_DAY,
  });
};
