import { useRecoilCallback } from 'recoil';

import { CreateSubscriptionError, useSubscription } from 'src/shared/hooks/useSubscription';
import { AddressInfo } from 'src/components/common/formcomponents/AddressLookup';
import { recoilRegistrationDataState } from 'src/store/recoil/registrationState';
import { updateMemberAddress } from 'src/store/services';
import { useAccountInfo } from 'src/shared/hooks/useAccountInfo';
import { useAmplitude } from 'src/components/amplitude';
import { useCart } from '../cart';
import { useCommodities } from 'src/shared/hooks/useCommodity';
import { getRefreshToken } from 'src/store/actions';
import { useDispatch } from 'react-redux';
import { RegistrationData } from 'src/types/supplierregistration';
export enum CheckoutErrorType {
  AccountCreation = 'Account Creation',
  CommodityCode = 'Commodity Code',
  InvalidForm = 'Invalid Form',
  None = '',
  Other = 'Other',
  Payment = 'Payment',
  Proration = 'Proration',
  UpdateAddress = 'Update Address',
}

interface CheckoutOptions {
  /** the address to be used as billing address */
  registrationData?: RegistrationData;
  /** the payment method token to be used */
  token?: { nonce: string };
  /**
   * In the event of a cost conflict between the front and back ends, this indicates
   * whether the OPS user has reviewed the discrepancy and elected to confirm
   */
  opsConfirmed?: boolean;
  /** the ID of the member checking out */
  memberId?: number;
  /** to avoid calling the CreateMember API when payment is getting failed */
  registrationMemberId?: number;

}

interface CheckoutResult {
  /** if the process was successful */
  status: boolean;
  /** which piece of the process failed, if any */
  errorType: CheckoutErrorType;
  /** The error message */
  errorMessage?: string;
}

/**
 * @description a wrapper for checkout functionality used throughout the application
 * @returns {
 *  checkout: (options: { billingAddress?: AddressInfo; token?: { nonce: string }; memberId?: number; }) =>
 *    Promise<{ status: boolean; errorType: CheckoutErrorType; errorMessage?: string }>
 * }
 *
 * @example const { checkout } = useCheckout();
 */
export function useCheckout() {
  const dispatch = useDispatch();
  const { createMemberAccount } = useAccountInfo();
  const { createSubscription } = useSubscription();
  const { updateCommodities } = useCommodities();
  const { cartItems, cartRenewal, discountedCartTotal, unchangedSubscribedProducts } = useCart();
  const { logEvent } = useAmplitude();

  /**
   * @description handles account creation, if applicable, and subscription creation
   * @returns CheckoutResult
   * @example const { status, errorType, errorMessage } = checkout({ memberId: auth.memberId });
   */
  const checkout = useRecoilCallback(
    ({ set, snapshot }) =>
      async ({
        registrationData,
        token,
        opsConfirmed,
        memberId,
        registrationMemberId,
      }: CheckoutOptions): Promise<CheckoutResult> => {
        let id = memberId && memberId > 0 ? memberId : registrationMemberId;
        let registration = false;
        if (!registrationMemberId) {
          
          if (id === undefined || !(id > 0)) {
            try {
              const memberAccount = await createMemberAccount(registrationData);
              id = memberAccount?.data.result.memberId;
              registration = true;
            } catch (error: any) {
              return {
                status: false,
                errorType: CheckoutErrorType.AccountCreation,
                errorMessage: error.message,
              };
            }
          }

          if (registrationData && registrationData.billingAddress && !registration) {
            try {
              await updateMemberAddress({ ...registrationData.billingAddress, MemberId: id });
            } catch (error: any) {
              return {
                status: false,
                errorType: CheckoutErrorType.UpdateAddress,
                errorMessage: error.message,
              };
            }
          }
        }
        else { registration = true; }

       

        if (discountedCartTotal <= 0 && !registration) {
          return {
            status: false,
            errorType: CheckoutErrorType.Payment,
            errorMessage: 'User cannot self-serve non-positive transaction',
          };
        }

        const newProducts = [...cartItems, ...unchangedSubscribedProducts];
        const payload = {
          isUpgrade: !registration,
          isRenewal: cartRenewal,
          memberId: id,
          opsConfirmed,
          products: newProducts,
          expectedTotal: registration ? undefined : discountedCartTotal,
          token: token?.nonce,
        };

        try {
          let success = false;

          // If no charge is attempted, consider checkout successful
          if (newProducts?.length) {
            
            const result = await createSubscription(payload);
            success = result.status;

            if (!success && result.errorType === CreateSubscriptionError.ProrationMismatch) {
              return {
                status: success,
                errorType: CheckoutErrorType.Proration,
              };
            }
          } else success = true;

          if (registration) {
            const recoilRegistrationData = await snapshot.getPromise(recoilRegistrationDataState);
            set(recoilRegistrationDataState, { ...recoilRegistrationData, token: token?.nonce, memberId: id });

            const commodityIds = recoilRegistrationData.commodityIds;
            if (commodityIds?.length) {
              try {
                await updateCommodities({ memberId: id, commodityCodeIds: commodityIds });
              } catch (error: any) {
                return {
                  status: false,
                  errorType: CheckoutErrorType.CommodityCode,
                  errorMessage: error.message,
                };
              }
            }

            if (token?.nonce) logEvent('RegistrationAddPayment', recoilRegistrationData);
          }
          else {
            dispatch(getRefreshToken());
          }

          return {
            status: success,
            errorType: CheckoutErrorType.None,
          };
        } catch (error: any) {
          return {
            status: false,
            errorType: CheckoutErrorType.Payment,
            errorMessage: error.message,
          };
        }
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartItems, cartRenewal, discountedCartTotal, unchangedSubscribedProducts],
  );

  return {
    checkout,
  };
}
