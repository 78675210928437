// TOREFACTOR: Rename file to InitialData.tsx

import { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions';
import { bindActionCreators } from 'redux';
import { MemberType, tokenCookieName } from '../utils/constants';
import { ReduxAction } from '../store/actions/utils';
import { actionPayloadTypes } from '../types/actiontypedef';
import { logOutFn, toastFn } from '../utils/helpers';
import { getCookie } from 'src/utils/cookie';
import { useAuthContext } from '../auth/AuthProvider';
import { useGetCommodityCodes } from 'src/query/queries/commodityCodes/getCommodityCodes';

interface Propstypes {
  getTopCommodities: (payload?: actionPayloadTypes) => ReduxAction;
  getAddAgencyrequiredDocs: (payload?: actionPayloadTypes) => ReduxAction;
  getAddAgencyBidTypes: (payload?: actionPayloadTypes) => ReduxAction;
  getAccountInfo: (payload?: actionPayloadTypes) => ReduxAction;
  getStateList: (payload?: actionPayloadTypes) => ReduxAction;
  getFiscalYear: (payload?: actionPayloadTypes) => ReduxAction;
  memberInfo: (payload?: actionPayloadTypes) => ReduxAction;
  getSelfDeclarations: (payload?: actionPayloadTypes) => ReduxAction;
  getAmplitudeUserProperties: (payload?: actionPayloadTypes) => ReduxAction;
  getaccountInfoCommodity: (payload?: actionPayloadTypes) => ReduxAction;
  getToursConfigurations: (payload?: actionPayloadTypes) => ReduxAction;
  getaccountinforequiredDocs: (payload?: actionPayloadTypes) => ReduxAction;
  getaccountinfobidtypes: (payload?: actionPayloadTypes) => ReduxAction;
  getCommodityTags: (payload?: actionPayloadTypes) => ReduxAction;
  children?: Component;
}

function InitialData(props: Propstypes) {
  const {
    getTopCommodities,
    getAddAgencyrequiredDocs,
    getAccountInfo,
    getStateList,
    getFiscalYear,
    memberInfo,
    getSelfDeclarations,
    getAmplitudeUserProperties,
    getaccountInfoCommodity,
    getToursConfigurations,
    getaccountinforequiredDocs,
    getaccountinfobidtypes,
    getCommodityTags,
    children,
  } = props;
  const { auth } = useAuthContext();

  const token = getCookie(tokenCookieName);
  const { data: commodityCodes } = useGetCommodityCodes();

  useEffect(() => {
    if (auth?.memberType && auth?.memberType === MemberType.ContentExtractor) {
      logOutFn();
      toastFn('error', 'Unauthorizied', 'loginunauthorized');
    }
  }, [auth]);

  useEffect(() => {
    if (
      auth?.memberType &&
      auth?.memberType !== MemberType.AgencyBuyer &&
      auth?.memberType !== MemberType.ContentExtractor &&
      auth?.token === token
    )
      getTopCommodities({ type: 'Member' });
  }, [auth, getTopCommodities, token]);

  useEffect(() => {
    if (auth?.memberType && auth?.memberType === MemberType.AgencyBuyer && auth?.token === token)
      getaccountinfobidtypes();
  }, [auth, getaccountinfobidtypes, token]);

  /* useEffect(() => {
    if (mt && mt === MemberType.AgencyBuyer) getAddAgencyrequiredDocs({ buyerId: mi });
  }, [mt, mi, getAddAgencyrequiredDocs]); */

  useEffect(() => {
    if (auth?.memberType && auth?.memberType === MemberType.AgencyBuyer && auth?.token === token)
      getaccountinforequiredDocs();
  }, [auth, getaccountinforequiredDocs, token]);

  useEffect(() => {
    if (
      auth?.memberType &&
      auth?.memberType !== MemberType.AgencyBuyer &&
      auth?.memberType !== MemberType.ContentExtractor
    )
      getAmplitudeUserProperties();
  }, [auth, getAmplitudeUserProperties, token]);

  useEffect(() => {
    if (
      auth?.memberType &&
      auth?.memberType !== MemberType.AgencyBuyer &&
      auth?.memberType !== MemberType.BasicSupplier &&
      auth?.memberType !== MemberType.ContentExtractor
    )
      getaccountInfoCommodity();
  }, [auth, getaccountInfoCommodity]);

  useEffect(() => {
    let getAccountinfoApiCall = true;
    if (
      getAccountinfoApiCall &&
      auth?.memberType &&
      auth?.memberType !== MemberType.ContentExtractor
    )
      getAccountInfo({ auth });
    return () => {
      getAccountinfoApiCall = false;
    };
  }, [auth, getAccountInfo]);

  useEffect(() => {
    if (auth?.memberType && auth?.memberType !== MemberType.ContentExtractor)
      memberInfo({ isCurrentMember: true });
  }, [auth, memberInfo]);

  useEffect(() => {
    if (auth?.memberType !== MemberType.ContentExtractor) getStateList();
  }, [auth, getStateList]);

  /* commenting this to use it on next release
  useEffect(() => {
    if (auth?.memberType !== MemberType.ContentExtractor) getCommodityTags();
  }, [auth, getCommodityTags]);
  */

  useEffect(() => {
    if (auth?.memberType && auth?.memberType !== MemberType.ContentExtractor) getFiscalYear();
  }, [auth, getFiscalYear]);

  useEffect(() => {
    if (auth?.memberType && auth?.memberType !== MemberType.ContentExtractor) getSelfDeclarations();
  }, [auth, getSelfDeclarations]);

  useEffect(() => {
    if (auth?.memberType && auth?.memberType !== MemberType.ContentExtractor && !auth?.opsId)
      getToursConfigurations();
  }, [auth, getToursConfigurations]);

  return <>{children}</>;
}
const connector = connect(
  (state: any) => ({
    shared: state.shared,
    commoditycodes: state.commoditycodes,
    accountinfo: state.accountinfo,
    memberinfo: state.memberinfo,
    bidssummary: state.bidssummary,
    tours: state.tours,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

export default connector(InitialData);
